.card {
    @apply .relative .flex .flex-col .rounded-sm .w-full .bg-white .shadow-lg .p-4;

    .card-header {
        @apply .text-black .mb-5;
        padding: 15px 15px 0 0;
    }

    .card-body {
        @apply .p-5;
    }

}
