//VUE GOOD TABLE CONFIGURATIONS

//---------------------------------------------------------------------------------------------------------------------/
// VARIABLES
//---------------------------------------------------------------------------------------------------------------------/
$base-grey: #DCDFE6 !default;
$bg-yellow-1: #8e8e8e; //#F2AE03
$bg-yellow-2: #e4e4e4;
$light-grey: #E4E7ED !default;
$lighter-grey: #EBEEF5 !default;
$extra-light: #F2F6FC !default;

$text-color: #606266 !default;
$secondary-text-color: #909399 !default;
$input-border-color: $base-grey !default;
$border-color: $base-grey !default;
$highlight-color: #F1F5FD !default;

$thead-bg-color-1: #F4F5F8 !default;
$thead-bg-color-2: #F1F3F6 !default;
$chevron-color: darken($thead-bg-color-1, 12%);

// link
$link-color: #409eff;
$notify-bg-color: #fdf9e8;
$notify-fg-color: #b38d28;

//---------------------------------------------------------------------------------------------------------------------/
// UTILS
//---------------------------------------------------------------------------------------------------------------------/
.vgt-right-align {
    text-align: right;
}

.vgt-left-align {
    text-align: left;
}

.vgt-center-align {
    text-align: center;
}

.vgt-pull-left {
    float: left !important;
}

.vgt-pull-right {
    float: right !important;
}

.vgt-clearfix::after {
    display: block;
    content: "";
    clear: both;
}

.vgt-responsive {
    width: 100%;
    overflow-x: auto;
    position: relative;
}

.vgt-text-disabled {
    color: $secondary-text-color;
}

//---------------------------------------------------------------------------------------------------------------------/
// WRAP
//---------------------------------------------------------------------------------------------------------------------/
.vgt-wrap {
    position: relative;
}

.vgt-fixed-header {
    position: absolute;
    z-index: 10;
    width: 100%;
    overflow-x: auto;
}

//---------------------------------------------------------------------------------------------------------------------/
// TABLE
//---------------------------------------------------------------------------------------------------------------------/
table.vgt-table {
    font-size: 16px;
    border-collapse: collapse;
    background-color: white;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
    border: 1px solid #ffffff;

    & td {
        padding: .75em .75em .75em .75em;
        vertical-align: top;
        border-bottom: 1px solid #ffffff;
        color: $text-color;
    }

    & tr.clickable {
        cursor: pointer;

        &:hover {
            background-color: $highlight-color;
        }
    }
}

//---------------------------------------------------------------------------------------------------------------------/
// TABLE-TH
//---------------------------------------------------------------------------------------------------------------------/
$sort-chevron-width: 5px;

.vgt-table {
    & th {
        padding: .75em 1.5em .75em .75em;
        vertical-align: middle;
        position: relative;

        &.sortable {
            cursor: pointer;

            &:after {
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                right: 6px;
                top: 50%;
                margin-top: -7px;
                border-left: $sort-chevron-width solid transparent;
                border-right: $sort-chevron-width solid transparent;
                border-bottom: $sort-chevron-width solid #ffffff;
            }

            &:before {
                content: "";
                position: absolute;
                height: 0;
                width: 0;
                right: 6px;
                top: 50%;
                margin-bottom: -7px;
                border-left: $sort-chevron-width solid transparent;
                border-right: $sort-chevron-width solid transparent;
                border-top: $sort-chevron-width solid #ffffff;
            }
        }
    }

    & th.line-numbers, & th.vgt-checkbox-col {
        padding: 0 .75em 0 .75em;
        color: $text-color;
        border-right: 1px solid $border-color;
        word-wrap: break-word;
        width: 25px;
        text-align: center;
    }

    & th.filter-th {
        padding: .75em .75em .75em .75em;
    }

    th.vgt-row-header {
        border-bottom: 2px solid $border-color;
        border-top: 2px solid $border-color;
        background-color: lighten($border-color, 10%);

        .triangle {
            width: 24px;
            height: 24px;
            border-radius: 15%;
            position: relative;
            margin: 0 8px;

            &:after {
                content: "";
                position: absolute;
                display: block;
                left: 50%;
                top: 50%;
                margin-top: -6px;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-left: 6px solid $text-color;
                margin-left: -3px;
                transition: 0.3s ease transform;
            }

            &.expand:after {
                transform: rotate(90deg);
            }
        }
    }

    thead th {
        span {
            @apply text-white;
        }
        vertical-align: bottom;
        padding-right: 1.5em;
        background: $bg-yellow-1;

        &.vgt-checkbox-col {
            vertical-align: middle;
        }

        &.sorting-asc {
            &:after {
                border-bottom: $sort-chevron-width solid #ffffff;
            }
        }

        &.sorting-desc {
            &:before {
                border-top: $sort-chevron-width solid #ffffff;
            }
        }
    }

    thead th:first-child {
        border-top-left-radius: 0.75rem;
    }

    thead th:last-child {
        border-top-right-radius: 0.75rem;
    }
}

//---------------------------------------------------------------------------------------------------------------------/
// STRIPED
//---------------------------------------------------------------------------------------------------------------------/
.vgt-table {
    span {
        color: #000;
    }
    &.striped tbody tr:nth-of-type(even) {
        background-color: #e2e8f0;
    }
}
//---------------------------------------------------------------------------------------------------------------------/
// TABLE FOOTER
//---------------------------------------------------------------------------------------------------------------------/
.vgt-wrap__actions-footer {
    border: 1px solid $border-color;
}

.vgt-wrap__footer {
    color: $text-color;
    padding: 1em;
    border: 1px solid $border-color;
    background: linear-gradient($thead-bg-color-1, $thead-bg-color-2);
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;

    .footer__row-count {
        &__label, &__select {
            display: inline-block;
            vertical-align: middle;
        }

        &__label {
            font-size: 14px;
            color: $secondary-text-color;
        }

        &__select {
            background-color: transparent;
            width: auto;
            padding: 0;
            border: 0;
            border-radius: 0;
            height: auto;
            font-size: 14px;
            margin-left: 8px;
            color: $text-color;
            font-weight: bold;

            &:focus {
                outline: none;
                border-color: $link-color;
            }
        }
    }

    .footer__navigation {
        font-size: 14px;

        &__page-btn, &__info, &__page-info {
            display: inline-block;
            vertical-align: middle;
        }

        &__page-btn {
            text-decoration: none;
            color: $text-color;
            font-weight: bold;
            white-space: nowrap;

            &:focus {
                outline: none;
                border: 0;
            }

            &.disabled,
            &.disabled:hover {
                opacity: 0.5;
                cursor: not-allowed;

                .chevron.left:after {
                    border-right-color: $text-color;
                }

                .chevron.right:after {
                    border-left-color: $text-color;
                }
            }

            .chevron {
                width: 24px;
                height: 24px;
                border-radius: 15%;
                position: relative;
                margin: 0 8px;
                top: 2px;

                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 50%;
                    top: 50%;
                    margin-top: -6px;
                    border-top: 6px solid transparent;
                    border-bottom: 6px solid transparent;
                }

                &.left::after {
                    border-right: 6px solid #F2AE03;
                    margin-left: -3px;
                }

                &.right::after {
                    border-left: 6px solid #F2AE03;
                    margin-left: -3px;
                }
            }
        }

        &__info, &__page-info {
            display: inline-block;
            color: $secondary-text-color;
            margin: 0 16px;
        }

        &__page-info {
            &__current-entry {
                width: 30px;
                text-align: center;
                display: inline-block;
                margin: 0 10px;
                font-weight: bold;
            }
        }
    }
}

 .footer__navigation__page-btn {
    span {
        color: $text-color!important;
    }
}

@media only screen and (max-width: 750px) {
    /* on small screens hide the info */
    .vgt-wrap__footer .footer__navigation__info {
        display: none;
    }
    .vgt-wrap__footer .footer__navigation__page-btn {
        margin-left: 16px;
        span {
            color: #000!important;
        }
    }
}

//---------------------------------------------------------------------------------------------------------------------/
// VGT-ICON
//---------------------------------------------------------------------------------------------------------------------/
.vgt-icon {
    @apply .w-3 .mx-2 .cursor-pointer;
}
