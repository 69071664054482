//---------------------------------------------------------------------------------------------------------------------/
// BUTTONS
//---------------------------------------------------------------------------------------------------------------------/
.btn {
    @apply block text-center py-2 px-12 rounded text-sm font-bold shadow-lg border-2 text-white cursor-pointer transition-all duration-200 ease-in-out;
    min-width: 170px;

    &:focus {
        @apply outline-none;
    }
}

.btn-disabled {
    @apply bg-light-gray border-light-gray cursor-not-allowed;

    &:hover {
        @apply bg-dark-gray border-dark-gray;
    }
}

.btn-primary {
    @apply bg-primary border-primary;

    &:hover {
        @apply bg-danger-hover border-danger-hover;
    }
}

.btn-info {
    @apply bg-info border-info;

    &:hover {
        @apply bg-info-hover border-info-hover;
    }
}

.btn-success {
    @apply bg-success border-success;

    &:hover {
        @apply bg-success-hover border-success-hover;
    }
}

.btn-warning {
    @apply bg-warning border-warning;

    &:hover {
        @apply bg-warning-hover border-warning-hover;
    }
}

.btn-danger {
    @apply bg-danger border-danger;

    &:hover {
        @apply bg-danger-hover border-danger-hover;
    }
}


//---------------------------------------------------------------------------------------------------------------------/
// OUTLINE BUTTONS
//---------------------------------------------------------------------------------------------------------------------/
.btn-outline {
    @apply block text-center py-2 px-12 rounded text-sm font-bold shadow-lg border text-white cursor-pointer transition-all duration-200 ease-in-out;
    min-width: 170px;

    &:focus {
        @apply outline-none;
    }
}

.btn-outline-disabled {
    @apply text-light-gray border-light-gray;

    &:hover {
        @apply text-white bg-dark-gray border-dark-gray;
    }
}

.btn-outline-primary {
    @apply text-primary border-primary;

    &:hover {
        @apply text-white bg-danger-hover border-danger-hover;
    }
}

.btn-outline-info {
    @apply text-info border-info;

    &:hover {
        @apply text-white bg-info-hover border-info-hover;
    }
}

.btn-outline-success {
    @apply text-success border-success;

    &:hover {
        @apply text-white bg-success-hover border-success-hover;
    }
}

.btn-outline-warning {
    @apply text-warning border-warning;

    &:hover {
        @apply text-white bg-warning-hover border-warning-hover;
    }
}

.btn-outline-danger {
    @apply text-danger border-danger;

    &:hover {
        @apply text-white bg-danger-hover border-danger-hover;
    }
}
