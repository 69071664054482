@tailwind base;

@tailwind components;

@import 'helpers/base';
@import 'helpers/animations';
@import 'helpers/buttons';
@import 'helpers/cards';
@import 'helpers/forms';
@import 'helpers/typography';
@import 'helpers/tables';

@tailwind utilities;

