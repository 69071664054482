//---------------------------------------------------------------------------------------------------------------------/
// GRUPO DE UM FORMULÁRIO
//---------------------------------------------------------------------------------------------------------------------/
.form-group {
    @apply .mb-3;

    label {
        @apply text-black;
    }

    input, textarea, select {
        @apply mb-2 border-light-gray border-2 rounded-md py-3 px-5 w-full outline-none;
        &:focus {
            @apply shadow-outline;
        }
    }
    input, select {
        @apply bg-white;
        height: 50px;
    }
    textarea {
        @apply resize-none h-48;
    }
}

//---------------------------------------------------------------------------------------------------------------------/
// FORMULÁRIOS DE INPUT
//---------------------------------------------------------------------------------------------------------------------/
.form-text {
    @apply flex flex-col text-dark-gray;

    label {
        @apply text-dark-gray font-medium;
    }

    input, textarea {
        @apply mb-2 border-light-gray border-2 rounded-md py-3 px-5 w-full;
    }
    textarea {
        @apply resize-none h-48;
    }
}

//---------------------------------------------------------------------------------------------------------------------/
// FORMULÁRIOS DE CHECKBOX
//---------------------------------------------------------------------------------------------------------------------/
.form-checkbox {
    @apply relative flex items-center;

    input {
        @apply opacity-0 w-0 h-0;
    }

    input:checked + .slider {
        @apply bg-primary;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(14px);
        -ms-transform: translateX(14px);
        transform: translateX(14px);
    }

    .slider {
        @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-light-gray;
        width: 32px;
        height:18px;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        @apply absolute rounded-full bg-white;
        content: "";
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 2px;
        -webkit-transition: .4s;
        transition: .4s;
    }

    label {
        margin-left: 33px;
        margin-top: -3px;
    }

}

//---------------------------------------------------------------------------------------------------------------------/
// MENSAGENS DE ERROS DE FORMULÁRIOS
//---------------------------------------------------------------------------------------------------------------------/
.errors {
    @apply mt-1 mb-3 text-red-500;
}

//---------------------------------------------------------------------------------------------------------------------/
// FORMULÁRIO GRID
//---------------------------------------------------------------------------------------------------------------------/

.grid-1 {
    @apply grid gap-4 grid-cols-1;
}

.grid-2 {
    @apply grid gap-4 grid-cols-2;
}

.grid-3 {
    @apply grid gap-4 grid-cols-3;
}

.grid-4 {
    @apply grid gap-4 grid-cols-4;
}
