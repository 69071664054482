//BEBAS NEUE
@font-face {
    font-family: 'BebasNeue';
    src: url("/fonts/BebasNeue/BebasNeue-Regular.ttf") format("truetype");
    font-weight: normal;
}

//RALEWAY
@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-Black.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-Thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Raleway';
    src: url('/fonts/Raleway/Raleway-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
    @apply .font-primary;
}

h1 {
    @apply .text-3xl;
}

h2 {
    @apply .text-2xl;
}

h3 {
    @apply .text-xl;
}

h4 {
    @apply .text-lg;
}

h5 {
    @apply .text-base;
}

h6 {
    @apply .text-sm;
}

p, span, a {
    @apply .font-primary;
}

p {
    @apply .text-base;
}

span {
    @apply .text-sm .text-light-gray;
}

a {
    @apply .text-primary;
}

a:hover {
    @apply .text-secondary;
}

blockquote {
    @apply .mb-4 .border .border-primary .p-4 .text-primary;
}

small {
    font-size: 80%;
}


ul {
    @apply .block .mt-0 .mb-4;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-end: 0;
    padding-inline-start: 40px;
}

pre {
    @apply bg-black text-white rounded py-3 px-5 leading-none;
}
